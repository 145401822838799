<template>
    <v-main>
        <Header></Header>
        <div class="section9">
          <v-container class="justify-center">

         <v-card-title class="justify-center"   style="margin:5em 0px 5em 0px; word-break: normal;"  primary-title>
             <h2>
                Maryud 103 FM
             </h2>
         </v-card-title>
        </v-container>
             <v-container >
                 <div class="space" >

            <v-card  hover outlined raised color="rgba(139, 214, 255,0.2)">
                <v-row>
                  <v-col>
                      <v-card-title class="justify-center" >    
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/location.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center">
                     Khartoum, Sudan
                      </v-card-title>
                          
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                        
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/Category.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center" >                          
                        Front End Development, UI/UX Design, Radio Streaming, SEO
                     </v-card-title>
                     
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                         <v-img height="3em" width="4em" contain :src="require('../assets/icons/industry.svg')"></v-img>
                      </v-card-title>
                         <v-card-title style="word-break:normal" class="justify-center">
                            Radio Station
                         </v-card-title>
                  </v-col>
                </v-row>
            </v-card>
                 </div>
              </v-container>
            <!-- </v-row> -->
            
        <v-container>
        <v-container>
        <v-row>
            <v-col cols="12" xl="3" lg="3" md="4" sm="12"   >
                 <v-container >
                <div class="space" >
                    <v-img contain max-height="15em" max-width="15em" :src="require('../assets/portfolio/logo3.png')">
                    
                    </v-img>
                </div>
                  </v-container>
            </v-col>

                





            <v-col>
                <v-container>    
                <v-row>
                <h2>About Maryud 103 FM</h2> 
                <div class="space">
                    <v-col>
                    <p>
                        Maryud103FM is a private Sudanese Radio Station operating from Khartoum. 
                        The main objective of Maryud103FM is to simultaneously create social, cultural,
                        and civic awareness while promoting local talents through radio shows, events,
                        and music production. 
                    </p>

                    </v-col>
                </div>
                </v-row>
                </v-container>
            </v-col>
        </v-row>
        </v-container>
         <v-container>
                    
        <v-row>
            <v-col >
                <h2>
                    What The Code Effect Did:
                </h2>
                <div class="space">
                    <v-container>
                        

                <p>
                   
                As an emerging radio station in Sudan, Maryud aims to build a strong online presence and increase the station's credibility and discoverability. <br>
                The Code Effect designed and developed Maryud’s web app to be a driving force in getting new listeners and to make it easy for visitors to discover what they’re about,
                 browse their content, and tune into their shows. <br>
                The User interface has been designed to be unique and to distinguish them 
                from the market, while also being consistent with their brand identity and visuals. 
                The web app is responsive and works on all screen sizes, and the visitor gets to enjoy 
                a smooth experience with fast loading speeds. <br>
                The Code Effect also implemented the live streaming feature allowing Maryud103FM to broadcast 
                its programs over the internet in a high-quality and reliable way, expanding the station’s reach
                 to a wide range of international audiences. 
                
                </p>
                    </v-container>
    </div>
            </v-col>
        </v-row>
         <v-container >
            <v-row>
              <v-col lg="6" md="12" sm="12">
                      <MockUp/>
              </v-col>
              <v-col lg="6" md="12" sm="12">
                  <iOsMockup></iOsMockup>
              </v-col>
            </v-row>
    </v-container>

          </v-container>
           
            <!-- <v-row>
                <v-col>

            <h2 class="ma-10">
                Mr.Alameen (Mena Save CEO) said about us:
            </h2>
        <div class="aurora">
            <v-container>
                
            <p>
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt minima, similique quisquam sint maiores fugit, velit repellendus at dolor nostrum laudantium laborum reiciendis sequi iure. Perferendis impedit facere deleniti ullam?"
            </p>
            </v-container>
        </div>
                </v-col>
            </v-row> -->
          </v-container>
          
        <Footer></Footer>
        </div>

    </v-main>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer'
import MockUp from '../components/MockUp'
import IOsMockup from '../components/iOsMockup.vue'

export default {
    name: 'Menasave',
    components:{
        Header,
        MockUp,
        Footer,
        IOsMockup 
    },
    
 

}
</script>
